<template>
  <div>
    <div class="row">
      <div class="col-md-9">
        <h3>Properties</h3>
      </div>
    </div>

    <loading v-if="loading" />

    <div class="card mt-3" v-if="!loading">
      <div class="card-body">
        <div class="row" v-if="selected_properties.length == 0">
          <div class="col-md-12">
            <h4>Filter</h4>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Date From</label>
              <date-picker
                input-class="form-control"
                v-model="filter.from"
                placeholder="Date From"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Date To</label>
              <date-picker
                input-class="form-control"
                v-model="filter.to"
                placeholder="Date To"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Sort Order</label>
              <select v-model="filter.sort" class="form-control">
                <option value="latest">Latest</option>
                <option value="oldest">Oldest</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Status</label>
              <select v-model="filter.status" class="form-control">
                <option value="all">All</option>
                <option value="pending">Pending</option>
                <option value="paid">Paid</option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Search</label>
              <input
                type="text"
                class="form-control"
                placeholder="Search Name, Email, Phone Number"
                v-model="filter.term"
              />
            </div>
          </div>
          <div class="col-md-8 text-end">
            <button class="btn btn-primary" @click.prevent="fetch">
              <i class="lni lni-search me-2"></i>Filter
            </button>
          </div>
        </div>
        <div class="row" v-if="selected_properties.length > 0">
          <div class="col-md-12">
            <h4>Actions</h4>
          </div>
          <div class="col-md-12 mb-3">
            <a
              href="#"
              class="btn btn-primary mr-2"
              @click.prevent="adminSetStatus('unpublished')"
              >Unpublish</a
            >
            <a
              href="#"
              class="btn btn-primary mr-2"
              @click.prevent="adminSetStatus('active')"
              >Publish</a
            >
            <a
              href="#"
              class="btn btn-primary mr-2"
              @click.prevent="adminSetStatus('active')"
              >Activate</a
            >
            <a
              href="#"
              class="btn btn-primary mr-2"
              @click.prevent="adminSetStatus('denied')"
              >Deny</a
            >
            <a class="btn btn-primary mr-2" href="#" @click.prevent="confirmDelete"
              >Delete</a
            >
          </div>
        </div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th><input type="checkbox" @change="toggleSelected" /></th>
              <th>ID</th>
              <th>Name</th>
              <th>Type</th>
              <th>Region</th>
              <th>Amount</th>
              <th>Added</th>
              <th v-if="filter.status != 'pending' && filter.status != 'paid'">
                Paid
              </th>
              <th>Views</th>
              <th>Owner</th>
              <th v-if="filter.status == 'paid'">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="properties.length == 0">
              <td colspan="8">There is no data to display</td>
            </tr>
            <tr v-for="(property, i) in properties" :key="`property-${i}`">
              <td>
                <input
                  class="select-property"
                  type="checkbox"
                  v-model="selected_properties"
                  :value="property.id"
                />
              </td>
              <td>{{ property.uniqid }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'admin.properties.show',
                    params: { id: property.id },
                  }"
                  >{{ property.name }}</router-link
                >
              </td>
              <td>{{ property.type }}</td>
              <td>{{ property.region }}</td>
              <td>{{ property.amount }}</td>
              <td>{{ property.added }}</td>
              <td v-if="filter.status != 'pending' && filter.status != 'paid'">
                <span
                  :class="`btn btn-sm rounded-pill btn-${
                    property.paid ? 'success' : 'danger'
                  }`"
                  >{{ property.paid ? "Yes" : "No" }}</span
                >
              </td>
              <td>{{ property.views }}</td>
              <td>{{ property.poster.name }}</td>
              <td v-if="filter.status == 'paid'">
                <span
                  :class="`btn btn-sm rounded-pill btn-${
                    property.status == 'pending'
                      ? 'warning'
                      : property.status == 'active'
                      ? 'success'
                      : 'danger'
                  }`"
                  >{{ property.status }}</span
                >
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'admin.properties.show',
                    params: { id: property.id },
                  }"
                  >View</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-12">
            <pagination
              v-model="pagination.page"
              :records="pagination.total"
              :perPage="pagination.per_page"
              @paginate="fetch"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      properties: [],
      loading: true,
      properties_count: null,
      pagination: { page: 1 },
      filter: { sort: "latest", from: null, to: null, term: "", status: "all" },
      selected_properties: [],
    };
  },

  methods: {
    fetch() {
      this.loading = true;
      let url = `/api/v1/admin/properties?search=${this.filter.term}&page=${this.pagination.page}&sort=${this.filter.sort}&status=${this.filter.status}`;

      if (this.filter.from) {
        url += `&date_from=${moment(this.filter.from).format("YYYY-MM-DD")}`;
      }

      if (this.filter.to) {
        url += `&date_to=${moment(this.filter.to).format("YYYY-MM-DD")}`;
      }

      this.axios.get(url).then((response) => {
        this.properties = response.data.properties;
        this.pagination = response.data.pagination;
        this.loading = false;
      });
    },

    toggleSelected() {
      Object.values(document.getElementsByClassName("select-property")).forEach(
        (el) => {
          el.checked = !el.checked;
          if (el.checked) {
            this.selected_properties.push(el.value);
          } else {
            this.selected_properties = [];
          }
        }
      );
    },

    adminSetStatus(status) {
      let data = {
        status: status,
        property_ids: this.selected_properties
      }

      this.axios
        .post(`/api/v1/admin/bulk-actions/update-property`, data)
        .then(() => {
          this.selected_properties = []
          this.fetch();
        });
    },
  },
};
</script>
